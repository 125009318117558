<template>
  <div class="for-employer">
    <section class="employer">
      <div class="container">
        <div class="employer_wrap">
          <div class="employer_col">
            <h2 class="employer_title">
              {{ $t("register_company") }}
            </h2>
            <BaseButton class="employer_btn employer_btn_lg">
              {{ $t("Register") }}
            </BaseButton>
          </div>
          <div class="employer_col">
            <img class="employer_img" src="@/assets/images/landing/for-employer-bg.png" alt="employer">
          </div>
          <BaseButton class="employer_btn employer_btn_sm">
            {{ $t("Register") }}
          </BaseButton>
        </div>
      </div>
    </section>
    <section class="service">
      <div class="container">
        <div class="service_wrap">
          <h2 class="service_title">
            {{ $t("how_works_for_employers") }}
          </h2>
          <div class="service_services">
            <div class="service_item">
              <div class="service_item_header">
                <span>1</span>
                <h3 class="name">
                  {{ $t("choose_employee") }}
                </h3>
              </div>
              <img src="@/assets/images/landing/service_table.png" alt="table">
            </div>
            <div class="service_item">
              <div class="service_item_header">
                <span>2</span>
                <h3 class="name">
                  {{ $t("study_resume") }}
                </h3>
              </div>
              <img src="@/assets/images/landing/service_cv.png" alt="cv">
            </div>
            <div class="service_item">
              <div class="service_item_header">
                <span>3</span>
                <h3 class="name">
                  {{ $t("talk_with_him") }}
                </h3>
              </div>
              <img src="@/assets/images/landing/service_chat.png" alt="chat">
            </div>
            <div class="service_item">
              <div class="service_item_header">
                <span>4</span>
                <h3 class="name">
                  {{ $t("invite_to_intern") }}
                </h3>
              </div>
              <img src="@/assets/images/landing/service_invite.png" alt="invite">
            </div>
          </div>
        </div>
      </div>
    </section>
<!--    <section class="registration">-->
<!--      <div class="container">-->
<!--        <div class="registration_wrap">-->
<!--          <h2 class="registration_title">-->
<!--            {{ $t("request_for_registration") }}-->
<!--          </h2>-->
<!--          <p class="registration_text">-->
<!--            {{ $t("request_text") }}-->
<!--          </p>-->
<!--          <BaseInput-->
<!--            v-model="form.company.value"-->
<!--            :placeholder="$t('company_name')"-->
<!--            :onerror="form.company.error"-->
<!--          />-->
<!--          <BaseInput-->
<!--            v-model="form.bin.value"-->
<!--            :placeholder="$t('bin')"-->
<!--            :onerror="form.bin.error"-->
<!--          />-->
<!--          <BaseInput-->
<!--            v-model="form.name.value"-->
<!--            :placeholder="$t('full_name')"-->
<!--            :onerror="form.name.error"-->
<!--          />-->
<!--          <BaseInput-->
<!--            v-model="form.email.value"-->
<!--            :placeholder="$t('email')"-->
<!--            :onerror="form.email.error"-->
<!--          />-->
<!--          <BaseInput-->
<!--            v-model="form.phone.value"-->
<!--            :placeholder="$t('phone')"-->
<!--            :onerror="form.phone.error"-->
<!--          />-->
<!--          <BaseButton class="registration_btn" @click="formSubmit()">-->
<!--            {{ $t("send_request") }}-->
<!--          </BaseButton>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->
  </div>
</template>

<script>
export default {
  name: 'ForEmployer',
  data() {
    return {
      // form: {
      //   company: {
      //     value: '',
      //     error: ''
      //   },
      //   bin: {
      //     value: '',
      //     error: ''
      //   },
      //   name: {
      //     value: '',
      //     error: ''
      //   },
      //   email: {
      //     value: '',
      //     error: ''
      //   },
      //   phone: {
      //     value: '',
      //     error: ''
      //   },
      // }
    }
  },
  methods: {
    // formSubmit() {
    //   const {company, bin, name, email, phone} = this.form
    //
    //   company.error = company.value ? '' : 'Неверное название'
    //   bin.error = bin.value ? '' : 'Неверное название'
    //   name.error = name.value ? '' : 'Неверное название'
    //   email.error = email.value ? '' : 'Неверное название'
    //   phone.error = phone.value ? '' : 'Неверное название'
    //
    // }
  }
}
</script>

<style lang="scss" scoped>
h2 {
  font-size: 38px;
  font-weight: 700;
  text-transform: uppercase;
}

h3 {
  font-size: 20px;
  font-weight: 600;
}

.for-employer {
  background: #F6F7F8;
}

.employer {
  padding-bottom: 40px;

  &_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &_title {
    margin-bottom: 22px;
  }

  &_col {
    width: 46%;

    &:first-child {
      padding: 68px 0;
      width: 60%;
    }

    img {
      max-width: 100%;
    }
  }

  &_btn {
    &_sm {
      display: none;
    }
  }
}

.service {
  padding-top: 40px;
  padding-bottom: 48px;

  &_wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 28px;
  }

  &_item {
    background: #E2E8F0;
    padding: 24px 20px 0 20px;
    overflow: hidden;
    border-radius: 16px;
    margin-bottom: 32px;

    &_header {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      gap: 8px;
      margin-bottom: 20px;

      span {
        background: #16C95D;
        border-radius: 50%;
        width: 24px;
        min-width: 24px;
        height: 24px;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #fff;
        font-weight: 600;
      }
    }

    img {
      max-width: 100%;
    }
  }
}

.registration {
  padding-bottom: 30px;

  &_wrap {
    background: #fff;
    border-radius: 16px;
    padding: 40px 14px;
    width: 100%;
    text-align: center;
  }

  &_title {
    margin-bottom: 4px;
  }

  &_text {
    max-width: 450px;
    margin: 0 auto 22px;
  }

  &_btn {
    margin: 0 auto;
    width: 410px;
  }
}

@media screen and (max-width: 1200px) {
  h2 {
    font-size: 36px;
  }
}

@media screen and (max-width: 992px) {
  h2 {
    font-size: 24px;
  }
  .service {
    &_wrap {
      grid-template-columns: 1fr;
    }
  }
}

@media screen and (max-width: 768px) {
  .employer {
    &_wrap {
      flex-direction: column;
    }

    &_col {
      width: 100%;
      padding-bottom: 0;

      &:first-child {
        padding-top: 30px;
        padding-bottom: 0;
        width: 100%;
      }
    }

    &_btn {
      &_sm {
        display: block;
        margin-top: 16px;
        width: 100%;
      }

      &_lg {
        display: none;
      }
    }
  }
  .service_item {
    padding: 20px 10px 0 10px;

    &_header {
      margin-bottom: 12px;
    }
  }
}
</style>
